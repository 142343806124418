import { SET_EMAIL_SUBJECT, GET_EMAIL_SUBJECT, RESET_EMAIL} from '../constants/constants'

export const setEmailSubject = (serviceId) => (dispatch) => {
    dispatch({ type: SET_EMAIL_SUBJECT, payload: serviceId })
}

export const getEmailSubject = () => (dispatch) => {
    dispatch({ type: GET_EMAIL_SUBJECT })
}

export const resetEmail = () => (dispatch) => {
    dispatch({ type: RESET_EMAIL })
}