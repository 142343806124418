import fractionalCpoLogo from "../../assets/images/fractional-cpo.png" 

const FractionalCpoLogo = () => {
    return <img
              src={fractionalCpoLogo}
              alt="Product Coaching Logo"
              width="50"
              height="50"
            />   ;
  };
  
  export default FractionalCpoLogo;