import image from "../../assets/images/WhiteLogoMd800px.png"
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

const evalShowFooter = () => {
  if (window.innerWidth < 770) {
    return false
  }
  return true;
}

const Footer = () => {

  const [showFooter, setShowFooter] = useState(evalShowFooter());
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function handleResize() {
    setShowFooter(evalShowFooter())
  }

    return (
      <>
      { showFooter ? (<>   
          <div className="row p-3 bg-black mt-3 footer">
            <div className="col-md-2">
              <img
                  src={image}
                  alt="AVC"
                  className="align-top d-inline-block mt-3 "
                  height="50px"
                />
            </div>
            <div className="col-md-8 d-flex justify-content-center ">
              <div>
                <div className="d-flex">
                  <h6 className="card-title --mt-7">Contact:</h6> 
                  <Link to="/contact" className="btn">
                    <span className="grey --mr-15">&nbsp;&nbsp;info@arborviewconsulting.com</span>
                  </Link>
                  <div className="--mr-15 --ml-15" style={{borderLeft: "2px solid grey", height: "35px"}}/>
                  <Link to="/services" className="btn">
                    <h6 className="card-title">Services</h6>
                  </Link>
                  <div className="--mr-15 --ml-15" style={{borderLeft: "2px solid grey", height: "35px"}}/>
                  <Link to="/about" className="btn">
                      <h6 className="card-title">About</h6>
                  </Link>
                </div>
                <span className="--copyright --ml-85">Copyright © 2018 - 2023 Arbor View Consulting, LLC  - All Rights Reserved.</span>
              </div>
            </div>
          </div>  
      </> ) : <div></div>

      }
    </>
    )
}
  
export default Footer;