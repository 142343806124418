import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import { sendEmail, getEmailSubject } from '../../redux/actions/EmailActions';
import EmailForm from './EmailForm';


const mapStateToProps = (state) => {
  let stateSubject
  if(!state.email.subject) {
    stateSubject = "Question for AVC"
  }
  else {
    stateSubject = state.email.subject;
  }

  return {
    subject: stateSubject
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getEmailSubject: () => dispatch(getEmailSubject()),
  }
}


class Contact extends Component { 
    constructor(props) {
        super(props);


    }

    
    render() {
      return (
        <Container>
          <div className="card p-3 bg-white border shadow --bottom-row">
          <div className="row justify-content-center"> 
            <div className="mt-3 col-11"> 
              <strong>To:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>info@arborviewconsulting.com
            </div>
          </div>
            <div className="row justify-content-center"> 
              <div className="mt-3 col-11"> 
                <EmailForm subject={this.props.subject} />
              </div>
            </div>
          </div>
          <div style={{height: '80px'}}></div>
        </Container>
      );
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Contact);