import productStrategyLogo from "../../assets/images/strategy_2.png" 

const StrategyConsultingLogo = () => {
    return <img
              src={productStrategyLogo}
              alt="Product Strategy Logo"
              width="50"
              height="50"
            />   ;
  };
  
  export default StrategyConsultingLogo;