import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { IoMdSend } from "react-icons/io";
import { Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import Reaptcha from 'reaptcha';
import axios from "axios";

const EmailForm = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm();

  const error_text = "Unexpected Error."
  const email_success = "Thank you.  The email has been sent to Arborview Consulting and a copy of the email has been send to you.  If you cant find it, please check your spam folder."

  const siteKey = "6LcAj5ElAAAAAAbUm-ECUzl3oSXY8pcbBLIyKcfE"
  const verificationUrl = "https://www.arborviewconsulting.com/recaptcha"
  const sendEmailUrl = "https://www.arborviewconsulting.com/sendemail"

  const [showCaptcha, setShowCaptcha] = useState(true);
  const [captchaConfirmed, setCaptchaConfirmed] = useState(false);
  const [captchaReady, setCaptchaReady] = useState(false);
  const [fromAddress, setFromAddress] = useState("");
  const [emailSubject, setEmailSubject] = useState(props.subject);
  const [emailBody, setEmailBody] = useState("");
  
  const onLoad = () => {
    if (!captchaReady) {
      setCaptchaReady(true)
    }
  };

  useEffect(() => {
    const localSubject = localStorage.getItem('emailSubject');
    if (!emailBody) {
      const str = localStorage.getItem('emailBody');
      setEmailBody(str)
    }
    if (localSubject) {
      setEmailSubject(localSubject)
    }
    if (!fromAddress) {
      const str = localStorage.getItem('fromAddress');
      setFromAddress(str);
    }
  }, [])

  const onSubmit = async (data) => {
    try {
      const request = {
        "from": fromAddress,
        "subject": emailSubject,
        "emailbody": emailBody
      }
      await axios.post(sendEmailUrl, request);
      setFromAddress("")
      setEmailBody("")
      localStorage.setItem('fromAddress', "");
      localStorage.setItem('emailBody', "");
      localStorage.setItem('emailSubject', "");
      alert(email_success)
    } catch (error) {
      console.error(error);
      alert(error_text + ": " + error)
    }
  };

  const handleRecaptchaChange = async (value) => {
      try {
        const response = await axios.post(verificationUrl, { "client-key": value });
        console.log("verify recaptha succeeded: ", response)
        setCaptchaConfirmed(true);
        setTimeout(() => {
          setShowCaptcha(false);
        }, 5000);
      } catch (error) {
        console.error(error);
        alert(error_text)
      }
  }

  const getSendButtonClass = () => {
    const classname = "btn gbs-button btn-outline-dark mt-4 "
    if (isSendDisabled()) {
      return classname + " --gray-bg"
    }
    return classname;
  }

  
  const isSendDisabled = () => {
    return errors.fromAddressField || errors.emailBodyField || !captchaConfirmed || !fromAddress || !emailBody
  }

  const handleFromAddressInput = (evt) =>  {
    setFromAddress(evt.target.value)
    localStorage.setItem('fromAddress', evt.target.value);
    setValue("fromAddressField", fromAddress);
    trigger("fromAddressField")
  }

  
  const handleEmailSubjectInput = (evt) =>  {
    setEmailSubject(evt.target.value)
    localStorage.setItem('emailSubject', evt.target.value);
    setValue("emailSubjectField", emailSubject);
    trigger("emailSubjectField")
  }

  const handleEmailBodyInput = (evt) =>  {
    setEmailBody(evt.target.value)
    localStorage.setItem('emailBody', evt.target.value);
    setValue("emailBodyField", emailBody);
    trigger("emailBodyField")
  }

  return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group d-flex mt-1">
                <label htmlFor="fromAddressField"><strong>From:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong> </label>
                <input
                    id="fromAddressField"
                    type="email"
                    className="form-control --width-300" 
                    data-cy="from-address-input"
                    value={fromAddress}
                    aria-describedby="emailHelp" 
                    placeholder="your.name@example.com"
                    {...register("fromAddressField", {
                    required: "Email address is required",
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                    },
                    })}
                    onChange={handleFromAddressInput}
                />
            
                {errors.fromAddressField && <span className="error-text">&nbsp;&nbsp;*&nbsp;{errors.fromAddressField.message}</span>}
                
            </div>
            <small id="emailHelp" className="form-text text-muted --ml-30 --mt-5">We'll never share your email with anyone else.&nbsp;&nbsp;See our&nbsp;
                <Link to="/privacypolicy">
                     Privacy Policy
                </Link> &nbsp;for details.
            </small>
            <div className="form-group d-flex mt-3">
                <label htmlFor="emailSubjectField"><strong>Subject:&nbsp;&nbsp;</strong></label>
                <input
                    id="emailSubjectField"
                    type="text"
                    className="form-control --width-300" 
                    data-cy="subject-input"
                    value={emailSubject}
                    aria-describedby="emailHelp" 
                    {...register("emailSubjectField", { required: true })}
                    onChange={handleEmailSubjectInput}
                />
            </div>
            <div className="form-group">
                <label htmlFor="emailBodyField"></label>
                <textarea
                    id="emailBodyField"
                    {...register("emailBodyField", { required: true })}
                    className="form-control" 
                    data-cy="comment-question-text"
                    value={emailBody}
                    rows="3" 
                    placeholder="How can I help you?"
                    onChange={handleEmailBodyInput}
                />
            </div>
            {errors.emailBodyField && <span className="error-text">*&nbsp;Please enter your comments or questions.</span>}  
        <div className="form-group">
            <Button 
                className={getSendButtonClass()}
                type="submit"
                disabled={isSendDisabled() ? true: false}
                data-cy="send-button"
            >
                <IoMdSend className="mb-1"/>&nbsp;&nbsp;Send
            </Button>
            
            </div>
            <br/>
            {showCaptcha && <Reaptcha
                                sitekey={siteKey}
                                onLoad={onLoad}
                                onVerify={handleRecaptchaChange} 
                                />
            }
        </form>
  );
};

export default EmailForm;