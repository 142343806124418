import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import {getServiceCardConfig } from '../../redux/actions/ServiceCardActions';
import { setEmailSubject } from '../../redux/actions/EmailActions';
import CollapsibleCard from '../../components/cards/CollapsibleCard.tsx';
import productCoaching from '../../assets/data/product-coaching.json'
import productProcess from '../../assets/data/product-process.json'
import fractionalCpo from '../../assets/data/fractional-cpo.json'
import strategyConsulting from '../../assets/data/strategy-consulting.json'
import ProductCoachingLogo from '../../components/logos/ProductCoachingLogo.js'
import ProductProcessLogo from '../../components/logos/ProductProcessLogo.js'
import StrategyConsultingLogo from '../../components/logos/StrategyConsultingLogo.js'
import FractionalCpoLogo from '../../components/logos/FractionalCpoLogo.js'
import { FRACTIONAL_CPO_SERVICE, 
  STRATEGY_CONSULTING_SERVICE,
  PRODUCT_COACHING_SERVICE, 
  PRODUCT_PROCESS_SERVICE } from '../../redux/constants/constants'

interface ServiceDetail {
  title: string;
  what: string;
  outcome: string;
}

interface Service {
  title: string;
  description: string;
  buttonText: string;
}

interface MyState {
    isFractionOpen: boolean,
    isStrategyOpen: boolean,
    isCoachingOpen: boolean,
    isProcessOpen: boolean,
    getCardConfig: any,
    clickCallBack: any,
    onButtonClick: any
}

const mapStateToProps = (state) => {
  return {
    isFractionOpen: state.serviceCardReducer.isFractionalServiceCardOpen,
    isStrategyOpen: state.serviceCardReducer.isStrategyServiceCardOpen,
    isCoachingOpen: state.serviceCardReducer.isCoachingServiceCardOpen,
    isProcessOpen: state.serviceCardReducer.isProcessServiceCardOpen
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onButtonClick: (serviceId) => dispatch(setEmailSubject(serviceId)),
    getCardConfig: () => dispatch(getServiceCardConfig())

  }
}

class ServicesPageClass extends  Component<MyState> {
    defaultDetails = new Array<ServiceDetail>()
    defaulteSrvice = {} as Service;

    constructor(props) {
      super(props);
      this.state = {
          productCoaching: productCoaching,
          productProcess: productProcess,
          fractionalCpo: fractionalCpo,
          strategyConsulting: strategyConsulting
      }
    }

    clickCallBack = (id) => {
      localStorage.setItem('emailSubject', "");
      this.props.onButtonClick(id);
    }

    componentDidUpdate() {
      this.scrollToService();
    }

    scrollToService = () => {
      setTimeout(() => {
        let scrollDiv;
        if (this.props.isFractionOpen) { 
          let divId = FRACTIONAL_CPO_SERVICE
          const divElement = document.getElementById(divId)!;
          scrollDiv = divElement.offsetTop;
        }
        
        else if (this.props.isStrategyOpen) { 
          let divId = STRATEGY_CONSULTING_SERVICE;
          const divElement = document.getElementById(divId)!;
          scrollDiv = divElement.offsetTop;
        }
        else if (this.props.isCoachingOpen) { 
          let divId =  PRODUCT_COACHING_SERVICE;
          const divElement = document.getElementById(divId)!;
          scrollDiv = divElement.offsetTop;
        }
        else if (this.props.isProcessOpen) { 
          let divId = PRODUCT_PROCESS_SERVICE;
          const divElement = document.getElementById(divId)!;
          scrollDiv = divElement.offsetTop;
        }
        window.scrollTo({ top: scrollDiv - 150 , behavior: 'smooth'});
      }, 200);
    }


    componentDidMount() {
      this.props.getCardConfig();
      this.scrollToService();
    }
    
    render() {
      return (
        <Container>
          <div id={FRACTIONAL_CPO_SERVICE}/>
              <CollapsibleCard 
                    serviceId={FRACTIONAL_CPO_SERVICE} 
                    open={this.props.isFractionOpen} 
                    serviceData={fractionalCpo} 
                    logo={<FractionalCpoLogo/>}
                    callBack={this.clickCallBack}
              />
          
          <br/>
          <div id={STRATEGY_CONSULTING_SERVICE}/>
              <CollapsibleCard 
                    serviceId={STRATEGY_CONSULTING_SERVICE} 
                    open={this.props.isStrategyOpen} 
                    serviceData={strategyConsulting} 
                    logo={<StrategyConsultingLogo/>} 
                    callBack={this.clickCallBack}
              />
          
          <br/>
          <div id={PRODUCT_COACHING_SERVICE}>
              <CollapsibleCard 
                  serviceId={PRODUCT_COACHING_SERVICE} 
                  open={this.props.isCoachingOpen} 
                  serviceData={productCoaching} 
                  logo={<ProductCoachingLogo/>} 
                  callBack={this.clickCallBack}
              />
          </div>
          <br/>
          <div id={PRODUCT_PROCESS_SERVICE}/>
              <CollapsibleCard 
                  serviceId={PRODUCT_PROCESS_SERVICE} 
                  open={this.props.isProcessOpen} 
                  serviceData={productProcess} 
                  logo={<ProductProcessLogo/>} 
                  callBack={this.clickCallBack}
              />
          <div className="card --bottom-row"></div>
        </Container>
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicesPageClass)
