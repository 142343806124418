import React from 'react';
import { Card } from 'reactstrap';
import { useNavigate } from "react-router-dom";

const HomeServiceCard = ({id, title, logo, description, clickCallBack, route, isReplaced}) => {
    const navigate = useNavigate();

    return (
        <Card className="d-flex btn p-3 mx-2 align-items-center border-0"
            onClick={() => {
                clickCallBack(id) // callback to parent
                navigate( route , {replace:isReplaced} )
            }
            }
        >
            <span className="text-center">{logo}</span>
            <br/>
            <div className="card-body">
                <h3 className="text-center card-title">{title}</h3>
                <br/>
                <span className="card-text">{description}</span>
            </div>
        </Card>
    );
  };
  
export default HomeServiceCard;