import { CHANGE_SERVICE_CARD, GET_SERVICE_CARD_CONFIG, SET_EMAIL_SUBJECT} from '../constants/constants'
import { FRACTIONAL_CPO_SERVICE, 
  STRATEGY_CONSULTING_SERVICE,
  PRODUCT_COACHING_SERVICE, 
  PRODUCT_PROCESS_SERVICE } from '../../redux/constants/constants'
  
  const initialStateServiceCards = {
    isFractionalServiceCardOpen: false,
    isStrategyServiceCardOpen: false,
    isCoachingServiceCardOpen: false,
    isProcessServiceCardOpen: false
  }

  export const getServiceCardConfig = (state=initialStateServiceCards, action={}) => {
    switch (action.type) {
      case GET_SERVICE_CARD_CONFIG:
        return state;
      default:
        return state;
    }
  }

  export const serviceCardReducer = (state=initialStateServiceCards, action={}) => {
    switch (action.type) {
      case CHANGE_SERVICE_CARD:
        switch(action.payload) {
          case FRACTIONAL_CPO_SERVICE:
            return Object.assign({}, state, {
              isFractionalServiceCardOpen: true,
              isStrategyServiceCardOpen: false,
              isCoachingServiceCardOpen: false,
              isProcessServiceCardOpen: false
            });
          case STRATEGY_CONSULTING_SERVICE:
            return Object.assign({}, state, {
              isFractionalServiceCardOpen: false,
              isStrategyServiceCardOpen: true,
              isCoachingServiceCardOpen: false,
              isProcessServiceCardOpen: false
            });
          case PRODUCT_COACHING_SERVICE:
            return Object.assign({}, state, {
              isFractionalServiceCardOpen: false,
              isStrategyServiceCardOpen: false,
              isCoachingServiceCardOpen: true,
              isProcessServiceCardOpen: false
            });
          case PRODUCT_PROCESS_SERVICE:
            return Object.assign({}, state, {
              isFractionalServiceCardOpen: false,
              isStrategyServiceCardOpen: false,
              isCoachingServiceCardOpen: false,
              isProcessServiceCardOpen: true
            });
          default:
            return Object.assign({}, state, {
              isFractionalServiceCardOpen: false,
              isStrategyServiceCardOpen: false,
              isCoachingServiceCardOpen: false,
              isProcessServiceCardOpen: false
            });
        }
      case GET_SERVICE_CARD_CONFIG:
        return state;
      default:
        return state
    }
  }
  