import './privacy.css'

export default function Samples() {
  return (
    <div>
        <div className="header flex-centered-col">
            <div className="flex-row-start">
            <h1 className="--ml70"><strong>Privacy Policy</strong></h1>
            
            </div>
        </div>
        <div className="flex-col-start --ml25 --mr25 " >   
            <span className="--ml10">This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</span>
            <br/>
            <span className="--ml10">We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the <a href="https://www.privacypolicies.com/privacy-policy-generator/">Privacy Policy Generator</a>.</span>
            <hr/>
            <h1 className="--ml10"><strong>Interpretation and Definitions</strong></h1>
            <br/>
            <h2 className="--ml15">Interpretation</h2>
            <li className="--ml35">The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</li>
            <br/>
            <h2 className="--ml15">Definitions</h2>
            <span className="--ml25">For the purposes of this Privacy Policy:</span>
            <ul>  
            <li className="--ml35"><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</li>   
            <li className="--ml35"><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>     
            <li className="--ml35"><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Arborview Consulting, LLC, 5351 Muirfield Ct, Boulder, CO, 80301.</li>
            <li className="--ml35"><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</li>         
            <li className="--ml35"><strong>Country</strong> refers to: Colorado,  United States</li> 
            <li className="--ml35"><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
            <li className="--ml35"><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</li>   
            <li className="--ml35"><strong>Service</strong> refers to the Website.</li>
            <li className="--ml35"><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</li>
            <li className="--ml35"><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
            <li className="--ml35"><strong>Website</strong> refers to Arborview Consulting, accessible from <a href="http://www.arborviewconsulting.com" rel="external nofollow noopener">http://www.arborviewconsulting.com</a></li>
            <li className="--ml35"><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
            </ul>
            <hr/>
            <h1 className="--ml10"><strong>Collecting and Using Your Personal Data</strong></h1>
            <br/>
            <h2 className="--ml15">Types of Data Collected</h2>
            <br/>
            <h3 className="--ml20">Personal Data</h3>
            <span className="--ml25">- While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</span>
            <li className="--ml35">
            <span>Email address</span>
            </li>
            <li className="--ml35">
            <span>First name and last name</span>
            </li>
            <li className="--ml35">
            <span>Phone number</span>
            </li>
            <li className="--ml35">
            <span>Address, State, Province, ZIP/Postal code, City</span>
            </li>
            <li className="--ml35">
            <span>Usage Data</span>
            </li>
            <br/>
            <h3 className="--ml20">Usage Data</h3>
            <li className="--ml35">Usage Data is collected automatically when using the Service.</li>
            <li className="--ml35">Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</li>
            <li className="--ml35">When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</li>
            <li className="--ml35">We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</li>
            <br/>
            <h3 className="--ml20">Tracking Technologies and Cookies</h3>
            <li className="--ml35">We do not use Cookies or similar tracking technologies </li>
             <br/>
            <h2 className="--ml15">Use of Your Personal Data</h2>
            <br/>
            <span className="--ml25">The Company may use Personal Data for the following purposes:</span>
            <li className="--ml35">
            <span>To provide and maintain our Service, including to monitor the usage of our Service.</span>
            </li>
            
            <li className="--ml35">
            <span>For the performance of a contract:     the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</span>
            </li>
            <li className="--ml35">
            <span>To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</span>
            </li>
            
            <li className="--ml35">
            <span>To manage Your requests: To attend and manage Your requests to Us.</span>
            </li>
            
            <br/>
            <h2 className="--ml15">Retention of Your Personal Data</h2>
            <li className="--ml35">The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</li>
            <li className="--ml35">The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</li>
            <li className="--ml35">The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</li>
            <br/>
            <h2 className="--ml15">Delete Your Personal Data</h2>
            <li className="--ml35">You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.</li>
            <li className="--ml35">Our Service may give You the ability to delete certain information about You from within the Service.</li>
            <br/>
            <h2 className="--ml15">Disclosure of Your Personal Data</h2>
            <br/>
            <h3 className="--ml20">Law enforcement</h3>
            <span className="--ml35">Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</span>
            <br/>
            <h3 className="--ml20">Other legal requirements</h3>
            <span className="--ml25">The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</span>
            
            <li className="--ml35">Comply with a legal obligation</li>
            <li className="--ml35">Protect and defend the rights or property of the Company</li>
            <li className="--ml35">Prevent or investigate possible wrongdoing in connection with the Service</li>
            <li className="--ml35">Protect the personal safety of Users of the Service or the public</li>
            <li className="--ml35">Protect against legal liability</li>
            
            <br/>
            <h2 className="--ml15">Security of Your Personal Data</h2>
            <li className="--ml35">The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</li>
            <br/>
            <h1 className="--ml10"><strong>Children's Privacy</strong></h1>
            <li className="--ml35">Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</li>
            <li className="--ml35">If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</li>
            <br/>
            <h1 className="--ml10"><strong>Links to Other Websites</strong></h1>
            <li className="--ml35">Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</li>
            <li className="--ml35">We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</li>
            <br/>
            <h1 className="--ml10"><strong>Changes to this Privacy Policy</strong></h1>
            <li className="--ml35">We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</li>
            <li className="--ml35">We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</li>
            <li className="--ml35">You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</li>
            <br/>
            <h1 className="--ml10"><strong>Contact Us</strong></h1>
            <span className="--ml35">If you have any questions about this Privacy Policy, you can contact us</span>
            <span className="--ml35">by visiting this page on our website: <a href="http://www.arborviewconsulting.com/#/contact" rel="external nofollow noopener">http://www.arborviewconsulting.com/#/contact</a></span>
            <br/>
            <br/>
            <br/>
             
        </div>
    </div>
  );
}
