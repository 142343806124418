import productCoachingLogo from "../../assets/images/product-coaching.png" 

const ProductCoachingLogo = () => {
    return <img
              src={productCoachingLogo}
              alt="Product Coaching Logo"
              width="50"
              height="50"
            />   ;
  };
  
  export default ProductCoachingLogo;