import Card from "../../components/cards/Card"
import React, {useEffect} from 'react';

const About = () => {

    const aboutTitle = "About Me"
    const aboutDescription = "I've worked in product for over 25 years. Throughout my career I've worked across industries - telecomm, hardware/software, supply chain, oil and gas, bio-tech, cybersecurity, and healthcare. I thrive in helping others reach their full potential. I am passionate about helping companies build high performing teams that focus on collaboration and the user at the center of all they do. I am a coach, advisor, mentor, and leader. I want to meet you where you are at and get you to the next goal."
    
    const approachTitle = "My Approach"
    const approachDesription = "I believe in a product approach that focuses on the user and the customer with an emphasis on collaboration, discovery and defining the problem to be solved before solutioning. To that end, the most successful companies invest in strong product leadership and empowering teams to solve the user and customer problems. I believe in bridging gaps and creating a strong collaborative approach across the organization."
    
    const whyTitle = "Why Me"
    const whyDescription = " Mentors and coaches make a difference. I seek to understand and provide the structure and framework that works for you and your team. I bring my extensive experience in building high performing teams and leading all levels of growth and transformation. I will work with you to customize a program that enable you and your company to achieve their goals."

    useEffect(() => {
      window.scrollTo(0, 0);
    },[])

    return (
      <div className="container-fluid">   
        <div className="row mt-3">
          <div className="col-lg-5">
            <Card colSize="" title={aboutTitle} description={aboutDescription}/>
            <br/>
            <Card colSize="" title={approachTitle} description={approachDesription} />
            <br/> 
            <Card colSize="" title={whyTitle} description={whyDescription}/>
          </div>
          <div className="col-lg-7 card-block bg-white">
              <img
                  src={require('../../assets/images/about_page.jpg')}
                  alt="AVC"
                  width="100%"
                  className="align-top d-inline-block --image"
                  height="100%"
                />
          </div>
        </div>
        <div className="row mt-3 --bottom-row">
        </div>
      </div>
    )
  }
  
export default About;