import { SEND_EMAIL, SET_EMAIL_SUBJECT, GET_EMAIL_SUBJECT, RESET_EMAIL } from '../constants/constants'
import { FRACTIONAL_CPO_SERVICE, 
    STRATEGY_CONSULTING_SERVICE,
    PRODUCT_COACHING_SERVICE, 
    PRODUCT_PROCESS_SERVICE } from '../../redux/constants/constants'

const initialEmailState = {
    subject: "Question for AVC",
    isEmailReset: false
}

export const email = (state=initialEmailState, action={}) => {
    let newState = {};
    switch (action.type) {
        case SET_EMAIL_SUBJECT: 
            switch(action.payload) {
                case FRACTIONAL_CPO_SERVICE:
                    newState = {subject: "Fractional/Interm CPO"};
                    break;
                case STRATEGY_CONSULTING_SERVICE:
                    newState = {subject: "Strategy Consulting"};
                    break;
                case PRODUCT_COACHING_SERVICE:
                    newState = {subject: "Product Coaching"};
                    break;
                case PRODUCT_PROCESS_SERVICE:
                    newState = {subject: "Product Process"};
                    break;

            }
            return Object.assign({}, state, newState);
        case SEND_EMAIL:
            return state;
        case GET_EMAIL_SUBJECT:
            return state;
        case RESET_EMAIL:
            localStorage.setItem('fromAddress', "");
            localStorage.setItem('emailBody', "");
            localStorage.setItem('emailSubject', "");
            newState = {subject: "Question for AVC", isEmailReset: true};
            return Object.assign({}, state, newState);
        default:
            return state;
    }
  }