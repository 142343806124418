import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import NabBar from './components/navbar/NabBar';
import Footer from './components/footer/Footer'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './pages/about/About';
import Home from './pages/home/Home';
import ServicesPageClass from './pages/services/ServicesPageClass.tsx';
import Contact from './pages/contact/Contact';
import Privacy from './pages/privacyPolicy/Privacy';
import './assets/sass/main.scss'

const showFooter = () => {
  const windowWidth = window.innerWidth;
  if (windowWidth < 800) {
    return false
  };
  return true
}

function App() {
  
  return (
    <Router>
            <NabBar/>
            <div className="body">
              <Routes >
                <Route exact path="/" element={ <Home/>} />
                <Route path="/services" element={ <ServicesPageClass/>} />
                <Route path="/about" element={ <About/>} />
                <Route path="/contact" element={ <Contact/>} />
                <Route path="/privacypolicy" element={ <Privacy/>} />
              </Routes>
            </div>
            {showFooter() ?  <Footer/>: <></>}
    </Router>
  );
}

export default App;