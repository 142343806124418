import React from 'react';

const CardContentWhatOutcome = ({title, what, outcome}) => {
    return (
        <>
            <h3 className="content-section">{title}</h3>
            <br/>
            <span><strong>What: </strong>{what}</span>
            <br/>
            <br/>
            <span><strong>Outcome: </strong>{outcome}</span>
            <br/>
            <br/>
        </>
    );
  };
  
export default CardContentWhatOutcome;