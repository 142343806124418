
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import HomeServiceCard from '../../components/cards/HomeServiceCard'
import productCoaching from '../../assets/data/product-coaching.json'
import productProcess from '../../assets/data/product-process.json'
import fractionalCpo from '../../assets/data/fractional-cpo.json'
import strategyConsulting from '../../assets/data/strategy-consulting.json'
import ProductCoachingLogo from '../../components/logos/ProductCoachingLogo.js'
import ProductProcessLogo from '../../components/logos/ProductProcessLogo.js'
import StrategyConsultingLogo from '../../components/logos/StrategyConsultingLogo.js'
import FractionalCpoLogo from '../../components/logos/FractionalCpoLogo.js'
import { FRACTIONAL_CPO_SERVICE, 
  STRATEGY_CONSULTING_SERVICE,
  PRODUCT_COACHING_SERVICE, 
  PRODUCT_PROCESS_SERVICE } from '../../redux/constants/constants'
import { changeServiceCard } from '../../redux/actions/ServiceCardActions';
import { resetEmail } from '../../redux/actions/EmailActions';
import image from '../../assets/images/focus_trees_1.8mb.jpg'
import portrait from '../../assets/images/julie_portrait3.jpg'

const mapDispatchToProps = (dispatch) => {
  return {
    onServiceSelect: (service) => dispatch(changeServiceCard(service)),
    onFirstVisit: () => dispatch(resetEmail())
  }
}

const mapStateToProps = (state) => {
  return {
    isEmailReset: state.email.isEmailReset
  }
}

const getTopCardStyle = () => {
  let pct;
  let widthpx;
  const windowWidth = window.innerWidth;
  if (windowWidth < 461) {
    pct = '50%'
    widthpx = '300px'
  }
  else if (windowWidth > 460 && windowWidth < 544) {
    pct = '44%'
    widthpx = '400px'
  }
  else if (windowWidth > 543 && windowWidth < 561) {
    pct = '43%'
    widthpx = '450px'
  }
  else if (windowWidth > 560 && windowWidth < 700) {
    pct = '42%'
    widthpx = '450px'
  }
  else if (windowWidth > 700 && windowWidth < 801) {
    pct = '38%'
    widthpx = '450px'
  }
  else if (windowWidth > 800  && windowWidth < 900) {
    pct = '33%'
    widthpx = '500px'
  }
  else {
    pct = '32%'
    widthpx = '550px'
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: pct,
    transform: 'translate(-50%, -50%)',
    textAlign: 'left',
    color: '#fff',
    width: widthpx
  };

  return style

}

class Home extends Component {
  constructor(props) {
      super(props);

      this.state = {
          strategyConsultingTitle: strategyConsulting['home-title'],
          strategyConsultingDesc: strategyConsulting['home-overview'],
          productCoachingTitle: productCoaching['home-title'],
          productCoachingDesc: productCoaching['home-overview'],
          fractionalCpoTitle: fractionalCpo['home-title'],
          fractionalCpoDesc: fractionalCpo['home-overview'],
          productProcessTitle: productProcess['home-title'],
          productProcessDesc: productProcess['home-overview'],
          topCardStyle: getTopCardStyle()
      }

      window.addEventListener('resize', this.handleResize);

  }

  handleResize = () => {
    this.setState({
      topCardStyle: getTopCardStyle()
    });
  }

  clickCallBack = (id) => {
    this.props.onServiceSelect(id);
  }

  componentDidMount() {
    this.props.onServiceSelect("none");
    //If this is the first vist for the session, reset the localstorage variables
    if (!this.props.isEmailReset) {
      this.props.onFirstVisit()
    }
    window.scrollTo(0,0)
  }

  render() {
    return (
      <div className="container-fluid">   
      {/*<div className="row mt-3">
          <div className="col-md-6 card-block bg-white">
              <img
                  src={image}
                  alt="AVC"
                  width="100%"
                  className="align-top d-inline-block --image"
                  height="100%"
                />
          </div>
          <div className="col-md-6 card-block bg-black">
            <div className="white mt-3 --mr-50">
              <h1 className="white mt-2 text-end">Product Coaching &</h1>
              <h1 className="white text-end">Fractional CPO</h1>
              <h4 className="light-green text-end">Optimize your product organization and</h4>
              <h4 className="light-green text-end">team to deliver efficiently and</h4>
              <h4 className="light-green text-end">collaboratively.</h4>
              <div className="p-3 d-flex mt-3 justify-content-end ">
                  <Link to="/services" className="btn white dark-green-bg">
                    Learn More
                  </Link>
              </div>
            </div>
          </div>
        </div>*/}
       
        <div className="row mt-3">
              <div style={{ position: 'relative', padding: 0 }}>
                <img
                  className='--image'
                  src={image}
                  alt="My Image"
                  style={{ width: '100%', height: '500px'}}
                />
                <div className="col-md-11 p-4  custom-card bg-dark bg-opacity-75"
                  style={this.state.topCardStyle}
                >
                
                  <Container >
                    <h1 className="white ">Strategic Advisory &</h1>
                    <h1 className="white ">Fractional CPO</h1>
                    <h4 className="light-green mt-1">Optimize your organization</h4>
                    <h4 className="light-green ">to deliver efficiently and</h4>
                    <h4 className="light-green ">collaboratively.</h4>
                    <Link to="/services" className="btn white dark-green-bg mt-3">
                            Learn More
                    </Link>
                    </Container>

              </div>
              <div className="col-md-12 p-1 --mr-5" >
                  <span className="--copyright" style={{ position: 'absolute', right: '0' }} >Photo by <a href='https://unsplash.com/@wizwow?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText' target="_blank">Donald Giannatti</a> on <a href='https://unsplash.com/' target="_blank">Unsplash</a>&nbsp;</span>
              </div>
          </div>
        </div>
        <div className="mt-3 d-flex justify-content-center">
          <Link to="/services" className="btn">
            <h1 className="card-title">Services</h1>
          </Link>
        </div>
        <div className="container-fluid"> 
        <div className="row  mt-3 d-flex justify-content-center">
          <div className="col-lg-3">
            <HomeServiceCard 
                  id={FRACTIONAL_CPO_SERVICE}
                  route={"/services"}
                  isReplaced={false}
                  title={this.state.fractionalCpoTitle}  
                  description={this.state.fractionalCpoDesc} 
                  logo={<FractionalCpoLogo/>} 
                  clickCallBack={this.clickCallBack}
            />
          </div>
          <div className="col-lg-3">
              <HomeServiceCard 
                    id={STRATEGY_CONSULTING_SERVICE}
                    route={"/services"}
                    isReplaced={false}
                    title={this.state.strategyConsultingTitle}  
                    description={this.state.strategyConsultingDesc} 
                    logo={<StrategyConsultingLogo/>} 
                    clickCallBack={this.clickCallBack}
              />
          </div>
          <div className="col-lg-3">
            <HomeServiceCard 
                  id={PRODUCT_COACHING_SERVICE}
                  route={"/services"}
                  isReplaced={true}
                  title={this.state.productCoachingTitle}  
                  description={this.state.productCoachingDesc} 
                  logo={<ProductCoachingLogo/>} 
                  clickCallBack={this.clickCallBack}
            />
          </div>
          <div className="col-lg-3">
            <HomeServiceCard 
                  id={PRODUCT_PROCESS_SERVICE}
                  route={"/services"}
                  isReplaced={false}
                  title={this.state.productProcessTitle}  
                  description={this.state.productProcessDesc} 
                  logo={<ProductProcessLogo/>} 
                  clickCallBack={this.clickCallBack}
            />
          </div>
          </div>
          <div className="row card-block --gray-bg mt-3 --bottom-row d-flex justify-content-center">
          <div className="col-md-3"></div>
          <div className="col-md-2">
              <img
                  src={portrait}
                  alt="AVC"
                  className="align-top d-inline-block mt-3 --image"
                  height="300px"
                />
          </div>
          <div className="col-md-1" style={{width: '70px'}}></div>
          <div className="col-md-6">
            <div className="mt-3">
              <div className="mt-5">
                <span className="--black text-start mt-5">MENTOR • ADVISOR • COACH • LEADER • TRANSFORMER</span>
              </div>
              <h1 className="--black mt-5 text-start">Julie Kopp</h1>
              <span className="--black text-start mt-5">I create - I build - I unpack wicked problems.</span>
              <br/>
              <span className="--black text-start">I help founders, founding teams, and leaders to</span>
              <br/>
              <span className="--black text-start">thrive and leverage their super powers.</span>
              <div className="d-flex mt-3 justify-content-start ">
                <Link to="/services" className="btn white dark-green-bg">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>
        </div>
      </div>
    )
  }
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Home);