import React, { Component } from 'react';
import { connect } from 'react-redux';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Navbar, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { changeServiceCard } from '../../redux/actions/ServiceCardActions';
import "./NavBar.css"
import { FRACTIONAL_CPO_SERVICE, 
         STRATEGY_CONSULTING_SERVICE,
         PRODUCT_COACHING_SERVICE, 
         PRODUCT_PROCESS_SERVICE } from '../../redux/constants/constants'


const mapDispatchToProps = (dispatch) => {
  return {
    onServiceSelect: (service) => dispatch(changeServiceCard(service))
  }
}


const getCollapseState = () => {
  if (window.innerWidth < 576) {
    return true;
  }
  return false;
} 

const getMargin = (side) => {
  const windowWidth = window.innerWidth;
  let marginNumber;
  if (windowWidth < 576) {
    marginNumber = "-15";
    side = "right"
  }
  else if (windowWidth >= 576 && windowWidth < 800) {
    marginNumber = "-30";
  }
  else if (windowWidth >= 800 && windowWidth < 1000) {
    marginNumber = "-50";
  }
  else {
    marginNumber = "-80";
  }

  let marginSide;
  if (side === "left") {
    marginSide = "--mr"
  }
  else {
    marginSide = "--ml"
  }

  let marginTop
  if (getCollapseState()) {
    marginTop = "";
  }
  else {
    marginTop = "--mt-80 "
  }

  const marginClasses = marginTop + marginSide + marginNumber
  
  return marginClasses

}


class NabBar extends  Component {

  constructor(props) {
    super(props);
    

    this.state = {
      leftNavLinkClassName: getMargin("left"),
      rightNavLinkClassName: getMargin("right"),
      isCollapsed: getCollapseState()
    }
    window.addEventListener('resize', this.handleResize);

  }

  handleResize = () => {
    this.setState({
      leftNavLinkClassName: getMargin("left"),
      rightNavLinkClassName: getMargin("right"),
      isCollapsed: getCollapseState()
    });
  }

  collapse = () => {
    var navbarCollapse = document.querySelector('.navbar-collapse');
    if (navbarCollapse.classList.contains('show')) {
      navbarCollapse.classList.remove('show');
    }
  }

  handleSelect = (eventKey) => {
    switch(eventKey) {
      case "1":
        this.props.onServiceSelect(STRATEGY_CONSULTING_SERVICE);
        break;
      case "2":
        this.props.onServiceSelect(FRACTIONAL_CPO_SERVICE);
        break;
      case "3":
        this.props.onServiceSelect(PRODUCT_COACHING_SERVICE);
        break;
      case "4":
        this.props.onServiceSelect(PRODUCT_PROCESS_SERVICE);
        break;
      default:
        console.log(`Unknown event key`);
    }
    this.collapse();
  }

  render () {
    
    return (
      <>  
        <Navbar bg="white" expand="sm" className="navbar navbar-default fixed-top --navbar-custom ">
          {this.state.isCollapsed && <Navbar.Brand href="/" className='--ml-15'>
                                                <img
                                                  src={require('../../assets/images/AVC_logo_n_text.jpg')}
                                                  alt="AVC"
                                                  width="125"
                                                  className="align-top d-inline-block --mt15"
                                                  height="100"
                                                />
                                        </Navbar.Brand>
            }
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mx-auto justify-content-between">
                <LinkContainer to="/">
                  <Nav.Link className={this.state.leftNavLinkClassName} onClick={this.collapse}><strong>Home</strong></Nav.Link>
                </LinkContainer>
                <NavDropdown className={this.state.leftNavLinkClassName} title={<span><strong>Services</strong></span>} id="basic-nav-dropdown" onSelect={this.handleSelect}>
                    <LinkContainer to="/services">
                        <NavDropdown.Item eventKey="2">Fractional/Interm CPO</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/services">
                        <NavDropdown.Item eventKey="1" className="--white-bg">Strategy Consulting</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/services">
                        <NavDropdown.Item eventKey="3">Product Coaching </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/services">
                        <NavDropdown.Item eventKey="4">Product Process </NavDropdown.Item>
                    </LinkContainer>
                </NavDropdown>
                {!this.state.isCollapsed && <Navbar.Brand href="/" className='--ml-15'>
                                                <img
                                                  src={require('../../assets/images/AVC_logo_n_text.jpg')}
                                                  alt="AVC"
                                                  width="125"
                                                  className="align-top d-inline-block --mt15"
                                                  height="100"
                                                />
                                        </Navbar.Brand>
                    }
                <LinkContainer to="/about">
                  <Nav.Link className={this.state.rightNavLinkClassName} data-cy="nav-bar-about" onClick={this.collapse}><strong>About</strong></Nav.Link>
                </LinkContainer>
                <LinkContainer to="/contact" className={this.state.rightNavLinkClassName} >
                  <Nav.Link data-cy="nav-bar-contact" onClick={this.collapse}><strong>Contact</strong></Nav.Link>
                </LinkContainer>
              </Nav>
            </Navbar.Collapse>
        </Navbar>
      </> 
    );
  }
}

export default connect(null, mapDispatchToProps)(NabBar)