const Card = ({colSize, title, description}) => {

    const clsName = "card p-3 " + colSize;

    return (
      <>   
          <div className={clsName}>
              <div className="p-3 d-flex justify-content-between ">
                <div>
                  <div className="d-flex">
                    <h1 className="card-title">{title}</h1>
                  </div>
                  <span className=" --mt-50">{description}</span>
                </div>
              </div>
          
          </div>  
      </>
    )
  }
  
export default Card;