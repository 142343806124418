import React, { useEffect, useState, ReactNode } from "react";
import CardContentWhatOutcome from './CardContentWhatOutcome.js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import {
  faChevronUp,
  faChevronDown
} from "@fortawesome/fontawesome-free-solid";


interface IProps {
  open?: boolean;
  logo: ReactNode;
  serviceData: object;
  serviceId: string
  callBack: any;
}

interface ServiceDetail {
  title: string;
  what: string;
  outcome: string;
  id: number;
}

interface Service {
  title: string;
  description: string;
  buttonText: string;
}


const CollapsibleCard: React.FC<IProps> = ({ serviceId, open,  logo, serviceData, callBack }) => {

  const defaultDetails = new Array<ServiceDetail>()
  const defaulteSrvice = {} as Service;

  const [isOpen, setIsOpen] = useState(open);

  const [service, setService] = useState(defaulteSrvice);
  const [details, setDetails] = useState(defaultDetails);


  useEffect(() => {
    let service = {} as Service;
    service.title = serviceData['service-title'];
    service.description = serviceData['service-description'];
    service.buttonText = serviceData['button-text'];
    let details = new Array<ServiceDetail>();
    serviceData['service-details'].forEach((detail) => {
      details.push(detail);
    })
    setService(service);
    setDetails(details);
  },[])

  useEffect(() => {
    setIsOpen(open)
  }, [open]);
  

  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClick = () => {
    callBack(serviceId)
  }

  return (
    <>
      <div className="card p-3 bg-white border shadow">
        <div>
          <div className="p-3 d-flex justify-content-between " id={serviceId}>
            <div>
            <div className="row d-flex">
              <div className="col-md-1">
                  {logo}   
                  <button type="button" className="btn" onClick={handleFilterOpening} style={{ position: 'absolute', right: '0' }}>
                      {!isOpen ? (<>
                        <span className="dark-green text-sm">See Details&nbsp;&nbsp;&nbsp;</span><FontAwesomeIcon icon={faChevronDown} /> 
                        </>
                        )
                       : (<>
                       <span className="dark-green text-sm">Close&nbsp;&nbsp;&nbsp;</span><FontAwesomeIcon icon={faChevronUp} />
                       </>
                      )}
                  </button>
              </div>
              <div className="col-md-10">
                  <h1 className="card-title --ml-30">{service.title}</h1>
              </div>
              {/*<div className="col-md-1">
                  <button type="button" className="btn" onClick={handleFilterOpening} style={{ position: 'absolute', right: '0' }}>
                      {!isOpen ? (<>
                        <span className="dark-green text-sm">See Details:&nbsp;&nbsp;&nbsp;</span><FontAwesomeIcon icon={faChevronDown} /> 
                        </>
                        )
                       : (<>
                       <span className="dark-green text-sm">Close:&nbsp;&nbsp;&nbsp;</span><FontAwesomeIcon icon={faChevronUp} />
                       </>
                      )}
                  </button>
                       </div>*/}
          </div>
          <div className="row d-flex">
          <div className="col-md-12">
            <span className=" --mt-50">{service.description}</span>
            </div>
          </div>
            </div>
          </div>
        </div>

        <div>
          <div>{isOpen && <>
                            <div className="p-3">
                              {details.map((servicDetail) => {                         
                                    return <CardContentWhatOutcome key={servicDetail.id} title={servicDetail.title} what={servicDetail.what} outcome={servicDetail.outcome}/>
                                  }
                                )
                              }
                            </div>
                            <div className="p-3 d-flex justify-content-center ">
                              <Link to="/contact" className="btn gbs-button btn-outline-dark" onClick={handleClick}>
                                  {service.buttonText}
                              </Link>
                            </div>
                          </>
              }
          </div>
        </div>
      </div>
    </>
  );
};

export default CollapsibleCard;