import productProcessLogo from "../../assets/images/product-process_2.png" 

const ProductProcessLogo = () => {
    return <img
              src={productProcessLogo}
              alt="Product Process Logo"
              width="50"
              height="50"
            />   ;
  };
  
  export default ProductProcessLogo;